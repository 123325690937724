import { createApp, provide, h } from 'vue'
import { ApolloClients } from '@vue/apollo-composable'
import * as Sentry from '@sentry/vue'
import VConsole from 'vconsole'
import App from './App.vue'
import router from './router'
import { apolloClient, dmzClient } from './apollo'
import './assets/scss/index.scss'
import components from '@/components'
import './router/permission'

import 'normalize.css/normalize.css'

if (import.meta.env.VITE_APP_CONSOLE === 'show') {
  const vConsole = new VConsole({})
  console.log('vConsole', vConsole)
}

const app = createApp({
  setup() {
    provide(ApolloClients, {
      default: apolloClient,
      dmz: dmzClient
    })
  },
  render: () => h(App)
})

if (import.meta.env.MODE === 'production') {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_APP_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration()
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

app.use(router).use(components).mount('#app')
