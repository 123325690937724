import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    redirect: '/investor-immigrant',
    component: () => import('../views/home/index.vue'),
    children: []
  },
  {
    path: '/investor-immigrant',
    name: 'investor-immigrant',
    component: () => import('../views/investor-immigrant/index.vue'),
    meta: {
      title: '投资移民'
    }
  },
  {
    path: '/entry-introduce',
    name: 'entry-introduce',
    component: () => import('../views/entry-introduce/index.vue'),
    meta: {
      title: '香港投资者入境介绍'
    }
  },
  {
    path: '/company-homepage',
    name: 'company-homepage',
    component: () => import('../views/company-homepage/index.vue'),
    meta: {
      title: '详情介绍'
    }
  },
  {
    path: '/entrust-manage-account',
    name: 'entrust-manage-account',
    component: () => import('../views/entrust-manage-account/index.vue'),
    meta: {
      title: '委托管理账户'
    }
  },
  {
    path: '/asset-category-introduce',
    name: 'asset-category-introduce',
    component: () => import('../views/asset-category-introduce/index.vue'),
    meta: {
      title: '获许投资资产概览'
    }
  },
  {
    path: '/total-assets',
    name: 'total-assets',
    component: () => import('../views/total-assets/index.vue'),
    meta: {
      title: '总资产概览'
    }
  },
  {
    path: '/invest-agreement',
    name: 'invest-agreement',
    component: () => import('../views/invest-agreement/index.vue'),
    meta: {
      title: '全权委托投资管理协议',
      keepAlive: true
    }
  },
  {
    path: '/entrustedAccount',
    name: 'entrustedAccount',
    component: () => import('../views/entrustedAccount/index.vue'),
    meta: {
      title: '委托管理协议'
    }
  },
  {
    path: '/viewProtocol',
    name: 'viewProtocol',
    component: () => import('../views/viewProtocol/index.vue'),
    meta: {
      title: '委托管理协议'
    }
  },
  {
    path: '/signature',
    name: 'signature',
    component: () => import('../views/signature/index.vue'),
    meta: {
      title: '签名'
    }
  },
  {
    path: '/membership-interest',
    name: 'membership-interest',
    component: () => import('../views/membership-interest/index.vue'),
    meta: {
      title: '会员权益'
    }
  },
  {
    path: '/membership-interest-concierge-service',
    name: 'membership-interest-concierge-service',
    component: () =>
      import('../views/membership-interest/pages/concierge-service.vue'),
    meta: {
      title: '礼宾服务'
    }
  },
  {
    path: '/membership-interest-vip',
    name: 'membership-interest-vip',
    component: () => import('../views/membership-interest/pages/vip.vue'),
    meta: {
      title: '机票酒店Vip预定'
    }
  },
  {
    path: '/membership-interest-fargo-space',
    name: 'membership-interest-fargo-space',
    component: () =>
      import('../views/membership-interest/pages/fargo-space.vue'),
    meta: {
      title: 'Fargo Space'
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

// 修改title
router.beforeEach((to, from, next) => {
  window.GH_NATIVE_SDK.setApptitle(to.meta.title || '投资移民')
  next()
})

export default router
